import { cva } from 'cva';
import React from 'react';

import { TAction } from '../../../types/actions';
import Button from '../../atoms/Button/Button';

export const enum EAdditionalButtonTheme {
  AdditionalButton1 = 'AdditionalButton1',
  AdditionalButton2 = 'AdditionalButton2',
}

type TAdditionalButtonProps = {
  button?: TAction;
  theme?: EAdditionalButtonTheme;
};

export const separatorCVA = cva('h-px shrink grow', {
  variants: {
    theme: {
      [EAdditionalButtonTheme.AdditionalButton1]: 'bg-control-200',
      [EAdditionalButtonTheme.AdditionalButton2]: 'bg-brand-500/10',
    },
  },
});

export const AdditionalButton: React.FC<TAdditionalButtonProps> = props => {
  const { button, theme } = props;
  if (!button) {
    return null;
  }
  return (
    <div className='flex w-full items-center gap-4 py-4'>
      <div className={separatorCVA({ theme })}></div>
      {'props' in button ? <Button {...button.props} /> : null}
      <div className={separatorCVA({ theme })}></div>
    </div>
  );
};
